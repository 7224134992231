import React from 'react'

import {
  Container,
  Typography,
  Grid,
  makeStyles,
  Avatar,
  ListItemIcon,
  ListItemText,
  ListItem,
  List,
  SvgIcon,
} from '@material-ui/core'
import SocialLinks from './SocialLinks'
import { graphql, useStaticQuery } from 'gatsby'
import { LocationQuery } from '../../@types/graphql-types'
import MoefitLogoTitle from '../../content/assets/logo.svg'
import MoefitLogoIcon from '../../content/assets/logo-icon.svg'
import ImovaLogo from '../../content/assets/logo-imova-icon.svg'
import ProdigizLogo from '../../content/assets/prodigiz-logo.svg'
import LogoTitle from "../../content/assets/logo.svg";
import {Link} from "gatsby-theme-material-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(7),
    paddingBottom: theme.spacing(7),
    zIndex: 1,
  },
  svg: {
    height: 80,
    transition: 'height .2s ease-out',
  },
  title: {
    display: 'inline-flex',
    alignSelf: 'center',
    paddingLeft: theme.spacing(2),
  },
  openingHours: {
    listStyle: 'none',
    padding: 0,
  },
  noXPad: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1.25),
    paddingBottom: theme.spacing(1.25),
  },
  addressList: {
    display: 'grid',
    gap: '2rem',
  },
  addressListItem: {
    alignItems: 'flex-start',
  },
  addressListItemText: {
    marginTop: 0,
    marginBottom: 0,
  },
  addressTitle: {
    lineHeight: 1,
    marginBottom: 8,
  },
  addressLogo: {
    backgroundColor: 'transparent',
    borderRadius: 0,
    display: 'grid',
    height: 'auto',
    marginRight: '1rem',
    width: 'auto',
  },
  addressLogoIcon: {
    width: '63px',
    height: 'auto',
  },
  footerBottom: {
    justifyContent: 'flex-end',
  },
  websiteBy: {
    display: 'inline-flex',
  },
  prodigizLogo: {
    marginLeft: '0.5rem',
    width: 'auto',
    height: '18px',
  },
}))
interface Footer {
  disablePaper?: boolean
}
const Footer: React.FC<Footer> = () => {
  const classes = useStyles(undefined)
  const data: LocationQuery = useStaticQuery(graphql`
    query LocationData {
      sanitySiteSettings {
        googleMap {
          asset {
            fixed(width: 389) {
              ...GatsbySanityImageFixed
            }
          }
        }
      }
    }
  `)
  return (
    <Container maxWidth="lg" style={{ zIndex: 1 }}>
      <Grid container className={classes.root} spacing={4}>
        <Grid item xs={12} sm md={4}>
          <Typography variant="h5" gutterBottom>
            Openingsuren
          </Typography>
          <Typography variant="body2" gutterBottom>
            Afhankelijk van de afspraken zullen de openingsuren variëren.
          </Typography>
          &nbsp;
          <Typography variant={'body2'} gutterBottom>
            Telefonisch bereikbaar:
            <br /> Maandag t.e.m. vrijdag: 9.00-19.00u, m.u.v. feestdagen.
            <br /> Buiten de uren, kan u gebruiken maken van onze voicemail.
          </Typography>
          {/* <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell className={classes.noXPad}>Maandag</TableCell>
                  <TableCell className={classes.noXPad} align="right">
                    08:00 - 21:30
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.noXPad}>Dinsdag</TableCell>
                  <TableCell className={classes.noXPad} align="right">
                    08:00 - 21:30
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.noXPad}>Woensdag</TableCell>
                  <TableCell className={classes.noXPad} align="right">
                    08:00 - 21:00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.noXPad}>Maandag</TableCell>
                  <TableCell className={classes.noXPad} align="right">
                    08:00 - 21:30
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.noXPad}>Donderdag</TableCell>
                  <TableCell className={classes.noXPad} align="right">
                    08:00 - 21:30
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.noXPad}>Vrijdag</TableCell>
                  <TableCell className={classes.noXPad} align="right">
                    08:00 - 21:30
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.noXPad}>Zaterdag</TableCell>
                  <TableCell className={classes.noXPad} align="right">
                    09:00 - 12:00
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.noXPad}>Zondag</TableCell>
                  <TableCell className={classes.noXPad} align="right">
                    Gesloten
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}
        </Grid>
        <Grid item xs={12} sm={8} md={4}>
          <Typography variant="h5" gutterBottom>
            Adres
          </Typography>
          <List className={classes.addressList} disablePadding>
            <ListItem className={classes.addressListItem} dense={false}>
              <ListItemIcon>
                <Avatar color={'transparent'} className={classes.addressLogo}>
                  <SvgIcon component={MoefitLogoIcon} viewBox="0 0 200 200" className={classes.addressLogoIcon}/>
                </Avatar>
              </ListItemIcon>
              <ListItemText
                  className={classes.addressListItemText}
                  primary={
                    <React.Fragment>
                      <Typography
                          className={classes.addressTitle}
                      >
                        MoeFit
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                      >
                        Jan Habexlaan 15 /BU1, <br /> 3600 Genk
                      </Typography>
                    </React.Fragment>
                  }
              />
            </ListItem>
            <ListItem className={classes.addressListItem} dense={false}>
              <ListItemIcon>
                <Avatar color={'transparent'} className={classes.addressLogo}>
                  <SvgIcon component={ImovaLogo} viewBox="0 0 673 359" className={classes.addressLogoIcon}/>
                </Avatar>
              </ListItemIcon>
              <ListItemText
                  className={classes.addressListItemText}
                  primary={
                    <React.Fragment>
                      <Typography
                          className={classes.addressTitle}
                      >
                        IMOVA
                      </Typography>
                    </React.Fragment>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                      >
                        Weg naar As 100 /BU1, <br /> 3600 Genk
                      </Typography>
                    </React.Fragment>
                  }
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Typography variant="h5" gutterBottom>
            Sociale media
          </Typography>

          <SocialLinks disablePaper dense />
        </Grid>
      </Grid>
      <Grid container className={[classes.root, classes.footerBottom].join(' ') }>
        <Link
            to={`https://www.prodigiz.be/?utm_source=moefit.be&utm_medium=referral&utm_campaign=footer_link`}
            target={'blank'}

            // variant="h6"
            // className={`${classes.title} ${isScrolledTop ? classes.showTitle : ''}`}
            // style={{ display: 'inline-flex', alignItems: 'center' }}
            className={classes.websiteBy}
        >
          <Typography variant={'body2'}>
            Supported by
          </Typography>
          <SvgIcon component={ProdigizLogo} viewBox="0 0 2324 346" className={classes.prodigizLogo}/>
        </Link>
      </Grid>
    </Container>
  )
}

export default Footer
