import React from 'react'
import {
  makeStyles,
  Icon,
  ListItem,
  ListItemText,
  List,
  Avatar,
  ListItemIcon,
  Typography,
} from '@material-ui/core'
import TwitterIcon from '@material-ui/icons/Twitter'
import InstagramIcon from '@material-ui/icons/Instagram'
import FacebookIcon from '@material-ui/icons/Facebook'
import { useStaticQuery, graphql } from 'gatsby'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import EventIcon from '@material-ui/icons/Event'
const ListItemLink = (props) => {
  return <ListItem button component={'a'} {...props} />
}
const useStyles = makeStyles((theme) => ({
  socialIcon: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.text.secondary
        : theme.palette.text.secondary,
  },
  socialContainer: {
    background: (disablePaper) => {
      return disablePaper ? undefined : theme.palette.background.paper
    },

    //  marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  secondaryText: {
    color: theme.palette.secondary.light,
  },
  inline: {
    display: 'inline',
  },
}))
// TODO: The NULL check is weird in this case, need to investigate
interface SocialLinks {
  disableGutters?: boolean
  disablePaper?: boolean
  dense?: boolean
}
const SocialLinks: React.FC<SocialLinks> = ({
  disableGutters,
  disablePaper,
  dense,
}) => {
  const classes = useStyles(disablePaper)
  const links = useStaticQuery(graphql`
    query SocialLinks {
      sanitySiteSettings {
        socials {
          facebook
          twitter
          instagram
        }
      }
    }
  `)
  const { facebook, twitter, instagram } = links.sanitySiteSettings.socials
  return (
    <>
      <List className={classes.socialContainer} disablePadding>
        {twitter && (
          <ListItemLink
            dense={dense}
            rel="noopener nofollow"
            target="_blank"
            href={`https://twitter.com/${twitter}`}
            button
            disableGutters={disableGutters}
          >
            <ListItemIcon>
              <Avatar color="secondary" className={classes.socialIcon}>
                <Icon color="action" component={TwitterIcon} />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary="Connect on Twitter"
              secondary={`@${twitter}`}
              secondaryTypographyProps={{ className: classes.secondaryText }}
            />
          </ListItemLink>
        )}
        {facebook && (
          <ListItemLink
            dense={dense}
            rel="noopener nofollow"
            target="_blank"
            href={`https://facebook.com/${facebook}`}
            button
            disableGutters={disableGutters}
          >
            <ListItemIcon>
              <Avatar color="secondary" className={classes.socialIcon}>
                <Icon color="action" component={FacebookIcon} />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary="Volg ons op Facebook"
              secondary={`@${facebook}`}
              secondaryTypographyProps={{ className: classes.secondaryText }}
            />
          </ListItemLink>
        )}
        {instagram && (
          <ListItemLink
            dense={dense}
            href={`https://instagram.com/${instagram}`}
            rel="noopener nofollow"
            target="_blank"
            button
            disableGutters={disableGutters}
          >
            <ListItemIcon>
              <Avatar color="secondary" className={classes.socialIcon}>
                <Icon color="action" component={InstagramIcon} />
              </Avatar>
            </ListItemIcon>

            <ListItemText
              primary="Connect on Instagram"
              secondaryTypographyProps={{ className: classes.secondaryText }}
              secondary={`${instagram}`}
            />
          </ListItemLink>
        )}
        <ListItemLink
          dense={dense}
          href={`https://altagenda.crossuite.com/MoeFit/`}
          rel="noopener nofollow"
          target="_blank"
          button
          disableGutters={disableGutters}
        >
          <ListItemIcon>
            <Avatar color="secondary" className={classes.socialIcon}>
              <Icon component={EventIcon} color="action" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary="Digitale afspraak"
            secondary={'Maak een online afspraak'}
          />
        </ListItemLink>
        <ListItemLink
          dense={dense}
          href={`tel:0499281863`}
          rel="noopener nofollow"
          button
          disableGutters={disableGutters}
        >
          <ListItemIcon>
            <Avatar color="secondary" className={classes.socialIcon}>
              <Icon component={PhoneIcon} color="action" />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary="Telefonische afspraak"
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textSecondary"
                >
                  0499 28 18 63
                </Typography>
              </React.Fragment>
            }
          />
        </ListItemLink>
      </List>
    </>
  )
}

export default SocialLinks
